<script setup lang="ts">
const { closeModal } = useModalStore();

const props = defineProps({
  onConfirm: {
    type: Function,
    default: () => {},
  },
  onCancel: {
    type: Function,
    default: () => {},
  },
  title: {
    type: String,
  },
  content: {
    type: String,
  },
});

function handleConfirm() {
  closeModal();
  props.onConfirm();
}

function handleCancel() {
  closeModal();
  props.onCancel();
}
</script>

<template>
  <BaseModal :title="props.title" :width="900">
    <p>{{ props.content }}</p>
    <template #footer>
      <BaseButton text="Annuler" type="secondary" @click="handleCancel" />
      <BaseButton text="Confirmer" @click="handleConfirm" />
    </template>
  </BaseModal>
</template>
